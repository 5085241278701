// eslint-disable-file
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { getData } from '../../utils/utilityFunctions';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const fadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  },
  100% {
    transform: scale('100');
    opacity: 1;
  }
`;

const EvuContainer = styled.div`
  width: 96%;
  max-width: 1170px;
  margin: auto;
  padding: 0;
  font-size: 20px;
  font-family: 'Roboto', sans-serif, ariel;
  h1 {
    font-size: 2em;
  }
  h3 {
    font-weight: 300;
  }
  .bold {
    font-weight: 900;
  }
  @media (max-width: 801px) {
    font-size: 18px;
  }
  .reviewsLink {
    margin: 10px 0 0;
  }
`;

const ReviewContainer = styled.div`
  box-shadow: 5px 10px 18px #999, 5px 10px 18px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
  @media (max-width: 801px) {
    flex-wrap: wrap;
    font-size: 16px;
    padding-top: 5px;
  }
`;

const SubSection = styled.div`
  align-items: center;
  text-align: center;
  padding: 25px 15px;
  background: ${({ background }) => background && background};
  width: 50%;
  img {
    max-width: 100%;
  }
  .veteranInfo {
    font-weight: 300;
    text-align: left;
    font-size: 17px;
  }
  .nmls {
    font-size: 14px;
    font-weight: 100;
    @media (max-width: 801px) {
      font-size: 10px;
    }
  }
  h3 {
    text-align: left;
    font-weight: 500;
    @media (max-width: 801px) {
      font-size: 16px;
    }
  }

  &.reviews {
    padding: 35px 15px;
  }

  .preApproveBtn {
    border: none;
    width: 100%;
    max-width: 300px;
    padding: 10px 0;
    color: #333;
    background: #fbcf3c;
    font-weight: 700;
    animation: ${fadeIn} 0.9s;
    &:hover {
      cursor: pointer;
    }
  }
  @media (max-width: 801px) {
    width: 100%;
  }
`;

const ReviewsContainer = styled.div`
  font-weight: 100;
  text-align: left;
  .lowerText {
    font-size: 17px;
  }
`;
const googleFontLink =
  'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';

const EvuThankyou = ({ config }) => {
  const [redirectLink, setRedirectLink] = useState('');
  const [clickPix, setClickPix] = useState('');

  const fetchData = async (url, data) => {
    const urlStringData = Object.keys(data)
      .map(key => `${key}=${data[key]}`)
      .join('&');

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Connection: 'keep-alive',
      },
      body: urlStringData,
    });
    const jsonData = await res.json();

    if (res && jsonData.return_url) {
      setRedirectLink(jsonData.return_url);
      setClickPix(
        `https://trkpls2.com/?ge4=5qi8uLPeLEbs1TXrViHEozHyoULgxPWH&s1=${getData(
          'loan_purpose'
        )}`
      );
    } else {
      setRedirectLink(
        `https://trkpls5.com/?bbz=mz1qEjNPyWlh0TuYPmlXUjHyoULgxPWH&s1=${getData(
          'loan_purpose'
        )}`
      );
      setClickPix(``);
    }
  };

  const trackConversion = trackingUrl => {
    if (trackingUrl && trackingUrl !== '[TRACKING_LINK]') {
      const trackingPixel = document.createElement('img');
      trackingPixel.src = trackingUrl;
      trackingPixel.width = 1;
      trackingPixel.height = 1;
      trackingPixel.style.display = 'none';
      document.body.appendChild(trackingPixel);
    }
  };

  const openUrl = (url, trackingUrl) => {
    trackConversion(trackingUrl);
    window.open(url, '_blank');
  };

  const translateLoanPurpose = () => {
    const loanPurpose = getData('loan_purpose');
    let value = '';
    if (loanPurpose === 'Refinance') {
      value = '1';
    } else {
      value = '2';
    }
    return value;
  };

  const translateCreditRating = () => {
    const creditRating = getData('credit_rating');
    let value = '';
    if (creditRating === 'average') {
      value = 'fair';
    } else {
      value = creditRating;
    }
    return value;
  };

  const translateLoanAmount = () => {
    const loanType = getData('loan_purpose');
    let value = '';
    if (loanType === 'Refinance') {
      value = getData('total_loan_amount');
    } else {
      value = getData('est_purchase_price');
    }
    return value;
  };

  const translateMovingDate = () => {
    const movingDate = getData('moving_date');
    const loanPurpose = getData('loan_purpose');
    const twoToSixMonthValues = ['3-6 Months', 'Within 90 Days'];
    let value = '';
    if (loanPurpose === 'Purchase') {
      if (movingDate === 'Right Away') {
        value = 'within a month';
      } else if (twoToSixMonthValues.includes(movingDate)) {
        value = 'in 2-6 months';
      } else {
        value = 'over 6 months';
      }
    } else {
      value = '';
    }
    return value;
  };

  const translateRefiReason = () => {
    const reason = getData('refi_purpose');
    let value = '';
    if (reason === 'Cash Out') {
      value = 'cashout';
    } else {
      value = 'other';
    }
    return value;
  };

  const translatePropertyType = () => {
    const propertyType = getData('property_type');
    let value = '';
    if (propertyType === 'Single Family') {
      value = 'single';
    } else if (propertyType === 'Townhouse') {
      value = 'townhome';
    } else if (
      propertyType === 'Mobile Home' ||
      propertyType === 'Manufactured'
    ) {
      value = 'manufactured';
    } else {
      value = 'single';
    }
    return value;
  };

  const translateHomeValue = () => {
    const loanPurpose = getData('loan_purpose');
    let value;
    if (loanPurpose === 'Refinance') {
      value = +getData('est_property_value');
    } else {
      value = +getData('est_purchase_price');
    }
    return value;
  };

  const translateCurrentRate = () => {
    const loanPurpose = getData('loan_purpose');
    let value = '';
    if (loanPurpose === 'Refinance') {
      value = +getData('mortgage_rate');
    } else {
      value = '';
    }

    return value;
  };

  const translateMortgageBalance = () => {
    const loanPurpose = getData('loan_purpose');
    let value = '';
    if (loanPurpose === 'Refinance') {
      value = +getData('mortgage_amount');
    } else {
      value = '';
    }

    return value;
  };

  const translatedDownPayment = () => {
    const percentDown = getData('estimated_down_payment');
    const costOfHouse = getData('est_purchase_price');
    const loanPurpose = getData('loan_purpose');

    let downPayment;
    const updateDecimal = () => {
      switch (+percentDown) {
        case 5:
          return 0.05;
        case 10:
          return 0.1;
        case 15:
          return 0.15;
        case 20:
          return 0.2;
        case 25:
          return 0.25;
        default:
          return '';
      }
    };

    if (loanPurpose === 'Purchase') {
      if (percentDown && costOfHouse) {
        downPayment = +costOfHouse * updateDecimal();
      }
    } else {
      downPayment = '';
    }
    return downPayment;
  };

  const translateCashAmount = () => {
    const loanPurpose = getData('loan_purpose');
    let value = '';

    if (loanPurpose === 'Refinance') {
      value = +getData('additional_cash_needed');
    } else {
      value = '';
    }
    return value;
  };

  useEffect(() => {
    const requestData = {
      need_json: 1,
      host_name: config?.scData?.siteName,
      addr_state: getData('state'),
      addr_city: getData('city'),
      address: getData('address'),
      zip_code: getData('zipcode'),
      email: getData('email'),
      first_name: getData('first_name'),
      last_name: getData('last_name'),
      phone_full: +getData('phone_primary'),
      property_state: getData('property_state'),
      value_home: +translateHomeValue(),
      lead_purpose_id: +translateLoanPurpose(),
      submission_type: 'va',
      credit_rating: translateCreditRating(),
      queue_override: '',
      current_rate: translateCurrentRate(),
      mortgage_balance: +translateMortgageBalance(),
      when_loan: translateMovingDate(),
      refi_reason: translateRefiReason(),
      bankruptcy: '',
      desired_loan_amount: +translateLoanAmount(),
      down_payment: translatedDownPayment(),
      military_branch: '',
      phone_full2: +getData('phone_primary'),
      lead_partner_lead_id: '',
      leadid_token: getData('universal_leadid'),
      src: 'rmpex',
      cmp: getData('cpid'),
      adg: getData('affiliate_id'),
      property_type: translatePropertyType(),
      vu_lead_price_paid: 20,
      cash_out_amount: translateCashAmount(),
      organization: 'vu',
      vu_brand: 'vu',
    };

    fetchData(
      'https://www.veteransunited.com/vendor_opq_backend.php',
      requestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <EvuContainer>
      <h1>Thank You!</h1>
      <h3>
        You’ve been matched with{' '}
        <span className="bold">Veterans United Home Loans,</span> the Nation’s
        #1 VA Lender by volume, who will be calling or emailing you soon:
      </h3>
      <ReviewContainer>
        <SubSection>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/evu-thankyou/images/logo.png"
            alt="Veterans United Home Loans"
          />
          <h3>
            Veterans United Home Loans -{' '}
            <span className="nmls">
              NMLS ID #1907
              <br />
              Not a government agency. Equal Opportunity Lender
            </span>
          </h3>
          <p className="veteranInfo">
            Veterans United Home Loans is the #1 VA Lender for HomeBuyers in the
            country. Our friendly Loan Experts and streamlined mortgage process
            have helped get more Veterans into homes than any other lender since
            2016 (according to the Department of Veteran Affairs Lender
            Statistics).
          </p>
          {redirectLink && (
            <button
              className="preApproveBtn"
              type="button"
              onClick={() => openUrl(redirectLink, clickPix)}
            >
              Get Pre-approved
            </button>
          )}
        </SubSection>
        <SubSection className="reviews" background="#ebebeb">
          <p>Customer Reviews</p>

          <img
            src="https://s3.amazonaws.com/sc-dba.com/evu-thankyou/images/fiveStar.png"
            alt="Star"
          />
          <p>
            Over 98% recommend <span className="bold">| 300,000+ </span> reviews
          </p>
          <hr />
          <ReviewsContainer>
            <p className="lowerText">
              "Our experience with Veterans United was seamless and full of
              communication. Our team kept us at ease during a stressful time."
              <br />- Thomas, U.S. Air Force
            </p>
            <p className="lowerText">
              "Wouldn't change any part of the home buying process. They made it
              easier than buying a car." - Tyler, U.S. Army
            </p>
          </ReviewsContainer>
        </SubSection>
      </ReviewContainer>
      <p className="reviewsLink">
        Visit{' '}
        <a
          href="https://www.veteransunited.com/reviews/"
          rel="noopener noreferrer"
          target="_blank"
        >
          veteransunited.com/reviews
        </a>{' '}
        to read more
      </p>
    </EvuContainer>
  );
};

export default withThankyouWrapper(EvuThankyou, googleFontLink);
